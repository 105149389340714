<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="">
      <div class="row">    
              <div class="col-md-12 col-12">
                 <div class="card-header">
                <h4 class="card-title"> Invoice </h4>
              </div>
              </div> 
            <div class="col-md-12 col-12" style="text-align:right;">
              <div class="srcpadmobie"> 
                             
<router-link v-if="grand_amount > paid_amount && this.action.includes('20')" class="btn btn-danger btn-sm" :to="{path:'/paynow/' + this.invoice_id}"> <i class="fas fa-rupee-sign"></i> <span class="mhide">Pay Now</span> </router-link>
                
  &nbsp;
  <router-link class="btn btn-success btn-sm" :to="{path:'/updateorder/' + this.invoice_id}"> <i class="fas fa-edit"></i> <span class="mhide">Edit Invoice</span> </router-link>
  &nbsp;
  <button onclick="window.print()" class="btn btn-info btn-sm"><i class="fa fa-print"></i> Print</button>&nbsp;
  <button @click="gereratePDF" class="btn btn-primary btn-sm"><i class="fa fa-print"></i> <span class="mhide">Download PDF</span></button>

            </div>
            </div>
            </div>   
            <div class="hgtblank">&nbsp;</div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">         
          <div class="offset-md-2 col-md-8">
            <div class="connectedSortable">
              <div class="row">
                <div class="col-md-12">
                  <div id="targetDiv">
                   <div id="titleadd">
                    <h3 class="m-0 marbot" id="heading">{{company_name}}</h3>
                    <p>{{address}}</p>
                   </div>   
                  <table width="100%" id="table2">                  
                   
                    <tr>
                      <td class="left"><b>Name</b></td>
                      <td class="left"> {{name}}</td>
                      <td class="left"><b>Mobile</b></td>
                      <td class="left"> {{mobile_no}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Order Date</b></td>
                      <td class="left"> {{pickup_date}}</td>

                      <td v-if="this.app_type != '0'" class="left"><b>Delivery Date</b></td>
                      <td v-if="this.app_type != '0'" class="left"> {{delivery_date}}</td>

                      <td v-if="this.app_type == '0'" class="left"></td>
                      <td v-if="this.app_type == '0'" class="left"></td>

                    </tr>
                    <tr>
                      <td colspan="4">&nbsp;</td>
                    </tr>
                  </table>
                  </div>
                </div>
              </div>
              <table class="table table-bordered" id="table4">
                <thead>
                  <tr>
                    <th class="item">item</th>
                    <th id="grandtotal" class="qty">Qty.</th>
                    <th class="amount">Amount</th>
                  </tr>
                </thead>
                <tbody>                  
                  <tr v-for="d in item_list" v-bind:key="d.id">
                    <td>{{ d.item_name }}</td>
                    <td>{{ d.quantity }}</td>
                    <td>Rs. {{ d.amount }}</td>
                  </tr>                  
                  <tr v-for="d in additional_charge" v-bind:key="d.id">
                    <td>{{ d.item_name }}</td>
                    <td>{{ d.quantity }}</td>
                    <td>Rs. {{ d.amount }}</td>
                  </tr>
                  <tr>
                    <td rowspan="5"><b>Receipt No.</b> #{{invoice_no}} <span class="red" v-if="order_type == 'Argent'">*</span> <br>
                      <div>&nbsp;</div>
                      <qrcode-vue :value="this.qrvalue" :size="size" level="H" />
                    </td>
                    <td><b>Total</b></td>
                    <td>Rs. {{grand_amount}}</td>
                  </tr>
                  <tr v-if="discount != '0'">
                    <td><b>Discount</b></td>
                    <td>Rs. {{discount}}</td>
                  </tr>
                  <tr>
                    <td><b>Grand Total</b></td>
                    <td>Rs. {{grand_amount}}</td>
                  </tr>
                  <tr>
                    <td><b>Advance</b></td>
                    <td>Rs. {{paid_amount}}</td>
                  </tr>
                  <tr>
                    <td><b>Balance</b></td>
                    <td>Rs. {{balance}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default { 
  extends:jsPDF, 
  components:{
         QrcodeVue,
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  data() {
    return {
      resourcesaction:[],  
      company_id:this.$store.state.auth.user.data[0].company_id,
      company_name:this.$store.state.auth.user.data[0].company_name,
      app_type:this.$store.state.auth.user.data[0].app_type, 
      address:this.$store.state.auth.user.data[0].address,
      role_id:this.$store.state.auth.user.data[0].role_id,
      invoice_id: this.$route.params.id,       
      item_list:[],
      additional_charge:[],
      order_type:this.order_type,
      value: this.qrvalue,
      user_id:this.$store.state.auth.user.data[0].id, 
      size: 100,            
    };
  },

  methods: {
    
    async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resourcesaction = response.data;               
              this.action = response.data.data[0].action_ids;   
              this.role_name = response.data.data[0].role_name;  
              console.log(this.action);           
        },
    gereratePDF(){        
          var doc = new jsPDF()         
          doc.autoTable({ 
            headStyles: 'StyleDef',
            theme: 'grid',            
            margin: { left: 10,right: 10 },   
            cellPadding: { top: 10,left: 10 },
            valign: 'middle',          
            html: '#table2' })
          doc.autoTable({ 
            theme: 'grid',
            margin: { right: 10 },
            html: '#table3' })          
          doc.autoTable({             
            theme: 'grid',
            margin: { left: 10,right: 10 },                      
            html: '#table4' })                          
          doc.save('table.pdf')         
    }
  },
  
  mounted(){
    axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/getorderdetails",  {
    invoice_id: this.$route.params.id,  
    company_id: this.company_id,  
	})
      .then(res => {
        //alert(res.data.data)
        var resData = res.data.data[0];
        this.invoice_details = resData.invoice_details;    
        this.item_list = resData.item_list;
        this.additional_charge = resData.additional_charge;   
        
        this.name = resData.invoice_details[0].name
        this.pickup_date = resData.invoice_details[0].pickup_date
        this.booking_date = resData.invoice_details[0].booking_date
        this.delivery_date = resData.invoice_details[0].delivery_date
        this.discount = resData.invoice_details[0].discount
        this.net_amount = resData.invoice_details[0].net_amount
        this.grand_amount = resData.invoice_details[0].grand_amount
        this.paid_amount = resData.invoice_details[0].paid_amount
        this.balance = resData.invoice_details[0].balance
        this.order_type = resData.invoice_details[0].order_type
        this.client_id = resData.invoice_details[0].client_id
        this.mobile_no = resData.invoice_details[0].mobile_no
        this.invoice_no = resData.invoice_details[0].invoice_no        

        this.qrvalue = this.invoice_id +- this.mobile_no
        console.log(resData)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })
      this.getAction();
  }
};
</script>

<style>
.connectedSortable { background: #fff;  padding: 5px; }
.item {border: 1px solid #ddd;}
.qty {border: 1px solid #ddd; width: 150px;}
.amount {border: 1px solid #ddd; width: 150px;}
.right { text-align: right;}
.left { text-align: left; padding-left: 10px;}
.red { color:#ff0000;}
.hrm { margin-top: 0px; margin-bottom: 5px;}
thead { background: #eee; }
#titleadd { text-align: center; margin-bottom: 30px; margin-top: 20px;}
#heading { font-size: 30px; font-weight: 600;}
@media (max-width: 600px) {
  .mhide { display: none;}
  h3 { font-size: 24px !important;
    font-weight: 700 !important;}
    .left { text-align: left; font-size: 11px;}
}
@media print { 
  body * { 
  visibility: hidden; 
  } 
  .offset-md-4 {
    margin-left: 33.333333%;
}
.col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
#heading { font-size: 35px; font-weight: 600; text-align: center;}
  #table2, #table2 * { 
  visibility: visible;  
  font-size: 12px;
  width: 55%;
  margin: 0 auto;  
  }  
  #table4, #table4 * { 
  visibility: visible; 
  font-size: 12px;
  width: 55%;
  margin: 0 auto;  
  }  
  #titleadd, #titleadd * { 
    visibility: visible; 
    text-align: center; 
  }
  .marbot { margin-bottom: 0px;}
  
  .hrm { margin-top: 10px; margin-bottom: 10px; color: #fff; background: #fff;}
  thead { background: #eee !important; }
} 

  @import '../../assets/dist/css/custom.css'
  
</style>

